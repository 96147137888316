// 引入工具函数
import {
  http,
  httpimg
} from '../index'



// 列表查询
export const caseList = params => http.post('/ohealth/api/v1/epidemicControl/case/caseList', params)

// 导出
export const exportList = params => httpimg.post('/ohealth/api/v1/epidemicControl/case/exportList', params)
